
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































.home {
  padding-top: 6rem;
  color: $c-black;
}

.homepage__hero {
  padding-bottom: 0;
}

.homepage__title {
  @include fluid(
    margin-bottom,
    (
      s: 35px,
      xl: 80px,
    )
  );

  color: $c-dark-blue;
  text-align: center;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;
      // color: $c-yellow;
    }
  }
}

.homepage__duo-vertical {
  margin-top: 11rem;
}
