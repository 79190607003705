
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































.home-card {
  position: relative;

  /* stylelint-disable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
  &:hover {
    ::v-deep {
      [class*='action--'] {
        .action__arrow {
          svg {
            &:first-child {
              animation: slide-bar 0.4s linear;
            }

            &:last-child {
              animation: slide-arrow 0.4s linear;
            }
          }
        }
      }
    }
  }
  /* stylelint-enable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */

  @include mq($until: s) {
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.home-card__content {
  position: relative;
  order: 1;
  width: 100%;
  padding-top: 4rem;

  @include mq($until: m) {
    text-align: center;
  }
}

.home-card__content__title {
  @extend %fw-normal;

  color: $c-dark-blue;
  letter-spacing: 1px;
}

.home-card__content__text {
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 26px,
      xl: 30px,
    )
  );

  margin-top: 2rem;
  color: $c-content;
  letter-spacing: 0.5px;
}

.home-card__content__btn {
  margin-top: 3.6rem;
}

.home-card__picture {
  width: 100%;

  @include mq($until: m) {
    margin-top: 1.5rem;
  }
}

.home-card__picture-outer {
  @include aspect-ratio(635, 530);
}

.home-card__picture__picture {
  @include get-all-space;

  object-fit: cover;
  border-radius: 3rem;
}

.home-card__link {
  @include get-all-space;
}

// Transition

@keyframes slide-bar {
  0% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(2.8rem);
    opacity: 0;
  }

  55% {
    transform: translateX(-2.8rem);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-arrow {
  0% {
    transform: translateX(-1.2rem);
  }

  45% {
    transform: translateX(-3.2rem);
    opacity: 0;
  }

  55% {
    transform: translateX(3.2rem);
    opacity: 1;
  }

  100% {
    transform: translateX(-1.2rem);
  }
}
