
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































.big-ctas {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  position: relative;
}

.big-ctas__content__title {
  @extend %fw-normal;

  color: $c-dark-blue;

  @include mq(m) {
    margin-left: 4rem;
  }
}

.big-ctas-inner {
  display: flex;
  align-items: flex-start;

  @include mq($until: m) {
    flex-direction: column;
    margin-top: 2.4rem;
  }

  @include mq(m) {
    margin-top: 4.5rem;
  }
}

.big-ctas__card {
  width: 100%;

  &:nth-child(odd) {
    @include mq(m) {
      ::v-deep {
        .home-card__content {
          width: col(9, 14);
        }

        .home-card__picture {
          @include aspect-ratio(69, 44);
        }
      }
    }
  }

  &:nth-child(even) {
    @include mq(m) {
      flex: col(8, 24);
      margin-top: 10rem;
      margin-left: col(2, 24);

      ::v-deep {
        .home-card__picture {
          @include aspect-ratio(1, 1);
        }
      }
    }
  }

  &:nth-child(odd) {
    @include mq(m) {
      flex: col(14, 24);
    }
  }
}
